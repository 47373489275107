import type { Reducer } from '@reduxjs/toolkit'
import { CartActions } from './cart';

export interface CartState { opened: boolean };

export type reducer = Reducer<CartState, CartActions>;

const fct: reducer = function(state, action) {
  switch (action.type) {
    case 'cart/open':
      return { opened: true }
    case 'cart/close':
      return { opened: false }
    default:
      return { opened: false }
  }
}

export const cartReducer = fct;