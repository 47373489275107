import { ApolloClient, gql, NormalizedCacheObject, useQuery } from "@apollo/client";
import { Project } from "../generated/graphql";

const QUERY_PROJECT_LINK = gql`
  query fct ($id: ID!) {
    projectLink(id: $id) {
      id
      prompt
      owner {
        oid
        oid_type
      }
      images {
        filename
        url
        sampleurl
      }
      createdAt
    }
  }
`;

export const useQueryProjectLink = (client: ApolloClient<NormalizedCacheObject>, id: string) => {
  const result = useQuery<{ projectLink: Project }>(QUERY_PROJECT_LINK, { 
    client, variables: { id }
  });

  return result;
}

const QUERY_PROJECT = gql`
  query fct ($id: ID!) {
    project(id: $id) {
      id
      prompt
      owner {
        oid
        oid_type
      }
      images {
        filename
        url
        sampleurl
      }
      createdAt
    }
  }
`;

export const useQueryProject = (client: ApolloClient<NormalizedCacheObject>, id: string) => {
  const result = useQuery<{ project: Project }>(QUERY_PROJECT, { 
    client, variables: { id }
  });

  return result;
}

const QUERY_ALL_PROJECTS = gql`
  query fct {
    projects {
      id
      prompt
      owner {
        oid
        oid_type
      }
      images {
        filename
        url
        sampleurl
      }
      createdAt
    }
  }
`;

export const useQueryAllProjects = (client: ApolloClient<NormalizedCacheObject>) => {
  const result = useQuery<{ projects: [Project] }>(QUERY_ALL_PROJECTS, { 
    client, variables: { }
  });

  return result;
}