import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Card, CardActionArea, CardContent, CardMedia, SnackbarContent, styled, Typography } from "@mui/material";
import React from "react";
import * as _ from "lodash";
import { ImageTypePair } from '../../constants/prompt_keywords';

export interface KeywordByImageProps {
  select: (keyword: string | undefined) => void;
  keywords: ImageTypePair[];
  title: string;
  description: string;
}

const none: ImageTypePair = {
  keyword: 'none',
  description: 'skip',
  image_url: '/prompt/none.webp'
}

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  position: "absolute", 
  bottom: 0, 
  backgroundColor: "rgb(256,256,256,0.95)",
  width: "100%",
  // "&:hover": {
  //   backgroundColor: "rgb(189,0,256,0.95)",
  // }
}));

// https://stackoverflow.com/questions/59178802/how-do-you-change-a-style-of-a-child-when-hovering-over-a-parent-using-mui-style
const StyledCard = styled(Card)(({ theme }) => ({
  "&:hover": {
    cursor: "pointer",
    "& .keywordCard": {
      backgroundColor: "rgb(189,0,255,0.95)"
    }
  }
}));

export default function KeywordByImage(props: KeywordByImageProps) {
  const { keywords, title, description, select } = props;
  
  const onClick = (str: string) => {
    const k = (str === 'none')? undefined : str;
    select(k);
  }

  return (
    <React.Fragment>
      <SnackbarContent message={description} />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {[none, ...keywords].filter(i => i.ignore !== true).map((item, index) => {
          return (
            <Grid xs={4}>
              <StyledCard sx={{ maxWidth: 345 }}>
                <CardActionArea onClick={() => {onClick(item.keyword)}}>
                  {
                    (() => {
                      if (item.keyword === 'none') {
                        return (<CardMedia>
                          <div style={{width: "100%", height: "200px", backgroundColor: "black"}} ></div>
                        </CardMedia>)
                      }

                      return (<CardMedia
                        component="img"
                        height="200"
                        width="200"
                        image={item.image_url}
                        alt={item.description}
                      />)
                    })()
                  }
                  <StyledCardContent className="keywordCard">
                    <Typography variant="subtitle2" color="text.secondary">
                    {item.description}
                    </Typography>
                  </StyledCardContent>
                </CardActionArea>
              </StyledCard>
            </Grid>
          )
        })}
      </Grid>
    </React.Fragment>
  );
}