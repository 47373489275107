export interface ImageTypePair {
  keyword: string;
  description: string;
  image_url: string;
  ignore?: boolean;
}

export interface PromptKeywordTypeConfig {
  title: string;
  description: string;
  keywords: ImageTypePair[];
  stringify: (key: string) => string;
}

export type PromptKeywordConfig = Record<string, PromptKeywordTypeConfig>;

export const prompt_keywords: PromptKeywordConfig = {
  angle: {
    title: "Angle",
    description: "How close and at what angle should your subject be?",
    stringify: (i) => i,
    keywords: [
      {
        keyword: 'aerial',
        description: 'aerial',
        image_url: '/prompt/angle/aerial.png'
      },
      {
        keyword: 'low angle',
        description: 'low angle',
        image_url: '/prompt/angle/lowangle.png'
      },
      {
        keyword: 'overhead',
        description: 'overhead',
        image_url: '/prompt/angle/overhead.png'
      },
      {
        keyword: 'over the shoulder',
        description: 'over the shoulder',
        image_url: '/prompt/angle/overtheshoulder.png'
      },
      {
        keyword: 'tilted frame',
        description: 'tilted frame',
        image_url: '/prompt/angle/tiltedframe.png'
      },
      {
        keyword: 'closeup',
        description: 'closeup',
        image_url: '/prompt/closeness/closeup.png'
      },
      {
        keyword: 'extreme closeup',
        description: 'extreme closeup',
        image_url: '/prompt/closeness/extremecloseup.png'
      },
      {
        keyword: 'extreme long shot',
        description: 'extreme long shot',
        image_url: '/prompt/closeness/extremelongshot.png'
      },
      {
        keyword: 'long shot',
        description: 'long shot',
        image_url: '/prompt/closeness/longshot.png'
      },
      {
        keyword: 'medium shot',
        description: 'medium shot',
        image_url: '/prompt/closeness/mediumshot.png'
      }
    ]
  },
  colorpalette: {
    title: "Color",
    // title: "Color Palette",
    description: "Select your Color Palette",
    stringify: (key: string) => `with "${key}" color palette`,
    keywords: [
      {
        keyword: 'anaglyph',
        description: 'anaglyph',
        image_url: '/prompt/colorpalette/anaglyph.png'
      },
      {
        keyword: 'black and white',
        description: 'black and white',
        image_url: '/prompt/colorpalette/blackandwhite.png'
      },
      {
        keyword: 'bleach bypass',
        description: 'bleach bypass',
        image_url: '/prompt/colorpalette/bleachbypass.png'
      },
      {
        keyword: 'colorful lighting',
        description: 'colorful lighting',
        image_url: '/prompt/colorpalette/colorfullighting.png'
      },
      {
        keyword: 'color splash',
        description: 'color splash',
        image_url: '/prompt/colorpalette/colorsplash.png'
      },
      {
        keyword: 'cyanotype',
        description: 'cyanotype',
        image_url: '/prompt/colorpalette/cyanotype.png'
      },
      {
        keyword: 'High-Key lighting',
        description: 'High-Key lighting',
        image_url: '/prompt/colorpalette/high_key_lighting.png'
      },
      {
        keyword: 'hipstamatic',
        description: 'hipstamatic',
        image_url: '/prompt/colorpalette/hipstamatic.png'
      },
      {
        keyword: 'holosexual',
        description: 'holosexual',
        image_url: '/prompt/colorpalette/holosexual.png'
      },
      {
        keyword: 'infrared',
        description: 'infrared',
        image_url: '/prompt/colorpalette/infrared.png'
      },
      {
        keyword: 'kodachrome (reds and greens)',
        description: 'Kodachrome (reds and greens)',
        image_url: '/prompt/colorpalette/kodachrome_reds_and_greens.png'
      },
      {
        keyword: 'lofi',
        description: 'lofi',
        image_url: '/prompt/colorpalette/lofi.png'
      },
      {
        keyword: 'Lomography',
        description: 'Lomography',
        image_url: '/prompt/colorpalette/lomography.png'
      },
      {
        keyword: 'red scale',
        description: 'red scale',
        image_url: '/prompt/colorpalette/redscale.png'
      },
      {
        keyword: 'solarized',
        description: 'solarized',
        image_url: '/prompt/colorpalette/solarized.png'
      }
    ]
  },
  illustrationtype: {
    // title: "Illustration Type",
    title: "Illustration",
    description: "Select your Illustration Type",
    stringify: (i) => i,
    keywords: [
      {
        keyword: '3D Render',
        description: '3D Render',
        image_url: '/prompt/illustrationtype/3D Render.png'
      },
      // {
      //   keyword: '1990\'s Disney cartoon',
      //   description: '1990\'s Disney',
      //   image_url: '/prompt/illustrationtype/1990_s Disney.png'
      // },
      {
        keyword: 'Airbrush cartoon',
        description: 'Airbrush',
        image_url: '/prompt/illustrationtype/Airbrush.png'
      },
      {
        keyword: 'Anime cartoon',
        description: 'Anime',
        image_url: '/prompt/illustrationtype/Anime.png'
      },
      {
        keyword: 'Ballpoint pen sketch',
        description: 'Ballpoint pen',
        image_url: '/prompt/illustrationtype/Ballpoint pen.png'
      },
      {
        keyword: 'Banksy stencil art',
        description: 'Banksy',
        image_url: '/prompt/illustrationtype/Banksy stencil art.png'
      },
      {
        keyword: 'Blueprint sketch',
        description: 'Blueprint',
        image_url: '/prompt/illustrationtype/Blueprint.png'
      },
      {
        keyword: 'Chinese watercolor painting',
        description: 'Chinese watercolor',
        image_url: '/prompt/illustrationtype/Chinese watercolor.png',
        // ignore: true
      },
      {
        keyword: 'Claymation art',
        description: 'Claymation',
        image_url: '/prompt/illustrationtype/Claymation.png'
      },
      {
        keyword: 'Coloring-in page',
        description: 'Coloring-in page',
        image_url: '/prompt/illustrationtype/Coloring-in page.png'
      },
      {
        keyword: 'Digital painting',
        description: 'Digital painting',
        image_url: '/prompt/illustrationtype/Digital painting.png'
      },
      {
        keyword: 'Pixel art',
        description: 'Pixel art',
        image_url: '/prompt/illustrationtype/Pixel art.png'
      },
      {
        keyword: 'Scientific Diagram',
        description: 'Scientific Diagram',
        image_url: '/prompt/illustrationtype/Scientific Diagram.png'
      },
      {
        keyword: 'Scratch art',
        description: 'Scratch art',
        image_url: '/prompt/illustrationtype/Scratch art.png'
      },
      {
        keyword: 'Tattoo art',
        description: 'Tattoo',
        image_url: '/prompt/illustrationtype/Tattoo.png'
      },
      {
        keyword: 'Ukiyo-e block print',
        description: 'Ukiyo-e',
        image_url: '/prompt/illustrationtype/Ukiyo-e.png'
      },
      {
        keyword: 'Vector Art cartoon',
        description: 'Vector Art',
        image_url: '/prompt/illustrationtype/Vector Art.png'
      },
      // {
      //   keyword: 'Vintage Disney cartoon',
      //   description: 'Vintage Disney',
      //   image_url: '/prompt/illustrationtype/Vintage Disney.png'
      // },
      {
        keyword: 'Voroni Diagram',
        description: 'Voroni Diagram',
        image_url: '/prompt/illustrationtype/Voroni Diagram.png'
      }
    ]
  },
  vibe: {
    title: "Style",
    // title: "In Style of",
    description: "Select your Style",
    stringify: (key: string) => `in style of ${key}`,
    keywords: [
      {
        keyword: 'Hello Kitty by Sanrio',
        description: 'Hello Kitty by Sanrio',
        image_url: '/prompt/vibe/2dcartoon.png'
      },
      {
        keyword: 'Abstract Expressionism by Jackson Pollock',
        description: 'Abstract Expressionism by Jackson Pollock',
        image_url: '/prompt/vibe/abstract_expressionism_jackson_pollock.png'
      },
      {
        keyword: 'americana',
        description: 'americana',
        image_url: '/prompt/vibe/americana.png'
      },
      {
        keyword: 'Ancient Egyptian Art',
        description: 'Ancient Egyptian Art',
        image_url: '/prompt/vibe/ancient_egyptian_art.png'
      },
      {
        keyword: 'art nouveau',
        description: 'art nouveau',
        image_url: '/prompt/vibe/artnouveau.png'
      },
      {
        keyword: '"blade runner" movie',
        description: 'blade runner',
        image_url: '/prompt/vibe/bladerunner.png'
      },
      // {
      //   keyword: 'bomba core',
      //   description: 'bomba core',
      //   image_url: '/prompt/vibe/bombacore.png'
      // },
      {
        keyword: '"breaking bad" tv show',
        description: 'breaking bad',
        image_url: '/prompt/vibe/breakingbad.png'
      },
      {
        keyword: 'bubble gum witch',
        description: 'bubble gum witch',
        image_url: '/prompt/vibe/bubblegumwitch.png'
      },
      {
        keyword: 'chic modernist',
        description: 'chic modernist',
        image_url: '/prompt/vibe/chicmodernist.png'
      },
      {
        keyword: 'cholo',
        description: 'cholo',
        image_url: '/prompt/vibe/cholo.png'
      },
      {
        keyword: 'christmas',
        description: 'christmas',
        image_url: '/prompt/vibe/christmas.png'
      },
      {
        keyword: 'chunyu',
        description: 'chunyu',
        image_url: '/prompt/vibe/chunyu.png'
      },
      // {
      //   keyword: 'citizen kane',
      //   description: 'citizen kane',
      //   image_url: '/prompt/vibe/citizenkane.png'
      // },
      {
        keyword: 'dark paradise',
        description: 'dark paradise',
        image_url: '/prompt/vibe/darkparadise.png'
      },
      {
        keyword: 'Disney circa 1990\'s',
        description: '1990\'s Disney',
        image_url: '/prompt/illustrationtype/1990_s Disney.png'
      },
      // {
      //   keyword: 'destilj',
      //   description: 'destilj',
      //   image_url: '/prompt/vibe/destilj.png'
      // },
      {
        keyword: 'doku kawaii',
        description: 'doku kawaii',
        image_url: '/prompt/vibe/dokukawaii.png'
      },
      // {
      //   keyword: 'dreamy',
      //   description: 'dreamy',
      //   image_url: '/prompt/vibe/dreamy.png'
      // },
      // {
      //   keyword: 'ethereal',
      //   description: 'ethereal',
      //   image_url: '/prompt/vibe/ethereal.png'
      // },
      // {
      //   keyword: 'expressionism',
      //   description: 'expressionism',
      //   image_url: '/prompt/vibe/expressionism.png'
      // },
      {
        keyword: '"final fantasy" video game',
        description: 'final fantasy',
        image_url: '/prompt/vibe/finalfantasy.png'
      },
      // {
      //   keyword: 'forest punk',
      //   description: 'forest punk',
      //   image_url: '/prompt/vibe/forestpunk.png'
      // },
      {
        keyword: 'futurama by matt groening',
        description: 'Futurama',
        image_url: '/prompt/vibe/futurama.png'
      },
      // {
      //   keyword: 'gloom core',
      //   description: 'gloom core',
      //   image_url: '/prompt/vibe/gloomcore.png'
      // },
      {
        keyword: 'goth',
        description: 'goth',
        image_url: '/prompt/vibe/goth.png'
      },
      {
        keyword: 'grunge',
        description: 'grunge',
        image_url: '/prompt/vibe/grunge.png'
      },
      {
        keyword: 'Half-tone dots (Roy Lichenstein)',
        description: 'Half-tone dots (Roy Lichenstein)',
        image_url: '/prompt/vibe/half_tone_dots.png'
      },
      {
        keyword: 'Hello Kitty by Sanrio',
        description: 'Hello Kitty by Sanrio',
        image_url: '/prompt/vibe/hello_kitty.png'
      },
      {
        keyword: 'hermaphroditus',
        description: 'hermaphroditus',
        image_url: '/prompt/vibe/hermaphroditus.png'
      },
      // {
      //   keyword: 'horror core',
      //   description: 'horror core',
      //   image_url: '/prompt/vibe/horrorcore.png'
      // },
      // {
      //   keyword: 'indie sleaze',
      //   description: 'indie sleaze',
      //   image_url: '/prompt/vibe/indiesleaze.png'
      // },
      {
        keyword: '"inglourious basterds" movie',
        description: 'inglourious basterds',
        image_url: '/prompt/vibe/inglouriousbasterds.png'
      },
      {
        keyword: '"in the mood for love" movie',
        description: 'in the mood for love',
        image_url: '/prompt/vibe/inthemoodforlove.png'
      },
      {
        keyword: 'Javanese Batik',
        description: 'Javanese Batik',
        image_url: '/prompt/vibe/javanese_batik.png'
      },
      {
        keyword: 'kawaii',
        description: 'kawaii',
        image_url: '/prompt/vibe/kawaii.png'
      },
      {
        keyword: 'Kente cloth',
        description: 'Kente cloth',
        image_url: '/prompt/vibe/kente_cloth.png'
      },
      // {
      //   keyword: 'lobotomy chic',
      //   description: 'lobotomy chic',
      //   image_url: '/prompt/vibe/lobotomychic.png'
      // },
      {
        keyword: '"looney tunes" cartoon show',
        description: 'looney tunes',
        image_url: '/prompt/vibe/looneytunes.png'
      },
      {
        keyword: 'mangue beat',
        description: 'mangue beat',
        image_url: '/prompt/vibe/manguebeat.png'
      },
      {
        keyword: 'ocean grunge',
        description: 'ocean grunge',
        image_url: '/prompt/vibe/oceangrunge.png'
      },
      {
        keyword: 'Pop Art by Andy Warhol',
        description: 'Pop Art by Andy Warhol',
        image_url: '/prompt/vibe/pop_art_andy_warhol.png'
      },
      {
        keyword: 'Solar Punk',
        description: 'Solar Punk',
        image_url: '/prompt/vibe/solarpunk.png'
      },
      // {
      //   keyword: 'retro futurism',
      //   description: 'retro futurism',
      //   image_url: '/prompt/vibe/retrofuturism.png'
      // },
      // {
      //   keyword: 'sad people',
      //   description: 'sad people',
      //   image_url: '/prompt/vibe/sadpeople.png'
      // },
      // {
      //   keyword: 'shibuya punk',
      //   description: 'shibuya punk',
      //   image_url: '/prompt/vibe/shibuyapunk.png'
      // },
      {
        keyword: 'soviet wave',
        description: 'soviet wave',
        image_url: '/prompt/vibe/sovietwave.png'
      },
      {
        keyword: '"star wars" by George Lucas',
        description: 'star wars',
        image_url: '/prompt/vibe/starwars.png'
      },
      {
        keyword: '"the simpsons" cartoon TV show',
        description: 'the simpsons',
        image_url: '/prompt/vibe/thesimpsons.png'
      },
      {
        keyword: 'Thomas Kinkade (artist)',
        description: 'Thomas Kinkade',
        image_url: '/prompt/vibe/thomas_kinkade_artist.png'
      },
      {
        keyword: 'urban core',
        description: 'urban core',
        image_url: '/prompt/vibe/urbancore.png'
      },
      {
        keyword: 'Vintage Disney cartoon',
        description: 'Vintage Disney',
        image_url: '/prompt/illustrationtype/Vintage Disney.png'
      },
      // {
      //   keyword: 'vapor wave',
      //   description: 'vapor wave',
      //   image_url: '/prompt/vibe/vaporwave.png'
      // },
      {
        keyword: 'western',
        description: 'western',
        image_url: '/prompt/vibe/western.png'
      },
      // {
      //   keyword: 'y2k',
      //   description: 'y2k',
      //   image_url: '/prompt/vibe/y2k.png'
      // }
    ]
  },
}