import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import React from "react";
import { CatalogItemAgeGroup, Image } from "../../generated/graphql";
import Size, { Sizing } from "./Size";

export interface SizeAndPreviewProps {
  onChange: (data: Sizing) => void;
  image: Image;
  client: ApolloClient<NormalizedCacheObject>;
}

// GraphQL
const QUERY_CATALOG = gql`
  query fct {
    catalog {
      id
      size
      color
    }
  }
`;

export default function SizeAndPreview(props: SizeAndPreviewProps) {
  const { image, client } = props;

  const [variant, setVariant] = React.useState<Number>(4018);
  const [product_image, setProductImage] = React.useState<string>();

  const onChange = (data: Sizing) => {
    setVariant(data.variant);
    setProductImage(data.product_image);
    props.onChange(data);
  };

  return (
    <React.Fragment>
      <Size client={client} onChange={onChange}/>
    </React.Fragment>
  );
}