import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Outlet } from 'react-router-dom';
import PrimaryAppBar from './AppBar';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Container, ScopedCssBaseline } from '@mui/material';
import Cart from '../purchase/Cart';
import { CartState } from '../../redux/cart_reducer';
import { useSelector } from 'react-redux'
import Footer from './Footer';

interface Props {
  client: ApolloClient<NormalizedCacheObject>;
}

export default function Layout({ client }: Props) {
  const cart = useSelector<{ cart: CartState }, CartState>((state) => state.cart);

  return (
    <React.StrictMode>
      <ScopedCssBaseline>
        {/* <Container maxWidth={"md"} style={{backgroundColor: "white"}}> */}
        <PrimaryAppBar />
        <Container maxWidth={"md"}>
          <Box>
            <Outlet />
          </Box>
          <Footer></Footer>
        </Container>
        <Cart client={client} opened={cart.opened} ></Cart>
      </ScopedCssBaseline>
    </React.StrictMode>
  );
}
