import * as React from 'react';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useQueryAllProjects, useQueryProject } from '../../code/project_query';
import { Avatar, LinearProgress, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material';

interface Props {
  client: ApolloClient<NormalizedCacheObject>;
}

export default function ProjectList({ client }: Props) {
  const { loading, data, error } = useQueryAllProjects(client);

  
  const content = (() => {
    if (loading) {
      return <LinearProgress />;
    }
    
    const projects = data?.projects;
    if (projects) {
      return projects.map(p => {
        const href = `#/admin/p/${p.id}`;
        const avatar = p.images[0]?.sampleurl ?? p.images[0]?.url;

        return (
          <ListItem alignItems="flex-start">
            <ListItemButton component="a" href={href}>
              <ListItemAvatar>
                { avatar && <Avatar src={avatar} /> }
              </ListItemAvatar>
              <ListItemText primary={p.prompt} />
            </ListItemButton>
          </ListItem>
        );
      });
    }

    return "oops, failed to load your projects";
  })();

  return (
    <React.StrictMode>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {content}
      </List>
    </React.StrictMode>
  );
}
