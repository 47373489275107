import { Badge, IconButton } from '@mui/material'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { useShoppingCart } from 'use-shopping-cart'
import { store } from '../../redux/store';

export default function StripeCart() {
  const { cartCount } = useShoppingCart();

  const handleCartOpen = (event: React.MouseEvent<HTMLElement>) => {
    // setAnchorEl(event.currentTarget);
    store.dispatch(({ type: 'cart/open' }));
  };
  
  return (
    <IconButton
      // size="large"
      // edge="end"
      // aria-label="account of current user"
      // aria-controls={menuId}
      aria-haspopup="true"
      onClick={handleCartOpen}
      color="inherit"
    >
      <Badge badgeContent={cartCount} color="primary">
        <ShoppingBasketIcon />
      </Badge>
    </IconButton>
  )
}