import * as React from 'react';
import Box from '@mui/material/Box';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useParams } from 'react-router-dom';
import ProjectComponent from '../project/ProjectComponent';
import { useQueryProjectLink } from '../../code/project_query';
import { LinearProgress } from '@mui/material';

interface Props {
  client: ApolloClient<NormalizedCacheObject>;
}

export default function Design({ client }: Props) {
  let { id } = useParams<"id">();

  const { loading, data, error } = useQueryProjectLink(client, id as string);

  
  const content = (() => {
    if (loading) {
      return <LinearProgress />;
    }
    
    const project = data?.projectLink;
    if (project) {
      return <ProjectComponent client={client} project={project} />
    }

    return "oops, failed to load your project";
  })();

  return (
    <React.StrictMode>
      {content}
    </React.StrictMode>
  );
}
