import { ApolloClient, gql, NormalizedCacheObject, useMutation } from "@apollo/client";
import { Mutation } from "../generated/graphql";

const MUTATION_GENERATE_FROM_PROMPT = gql`
  mutation fct($oid: String!, $prompt: String!) {
    createProject(oid: $oid, prompt: $prompt) {
      id
      prompt
      owner {
        oid
        oid_type
      }
      images {
        filename
        url
        sampleurl
      }
      createdAt
    }
  }
`;

export const useCreateProject = (client: ApolloClient<NormalizedCacheObject>) => {
  // https://www.apollographql.com/docs/react/data/mutations/
  const [mutate, response] = useMutation<Pick<Mutation, 'createProject'>>(MUTATION_GENERATE_FROM_PROMPT, { 
    client, variables: { oid: '[unknown]', prompt }
  });

  return {
    mutate, response
  };
}