import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import _ from "lodash";
import TextPromptField from "./TextPromptField";

export interface PromptProps {
  prompt: string | undefined;
  hints?: string[];
  submit?: (prompt: string) => void;
}

const MAX_LEN = 400;

export default function TextPrompt(props: PromptProps) {
  const { hints, submit } = props;

  const [prompt, setPrompt] = React.useState<string>(props.prompt ?? "");

  const onHintClick = (hint: string) => {
    setPrompt(hint);
  }

  const h = (() => {
    if (!hints?.length) {
      return;
    }

    return (
      <Box>
        <Grid container spacing={2} textAlign="center">
          <Grid xs={12} alignContent={"center"}><Typography variant="h4">Need some inspiration?</Typography></Grid>
          {_.map(hints, (h, i) => {
            return (
              <React.Fragment>
                <Grid xs={12} alignContent={"center"}><Button onClick={() => onHintClick(h)}>{h}</Button></Grid>
              </React.Fragment>
            )
          })}
        </Grid>
      </Box>
    )
  })();

  const s = (() => {
    if (!submit) {
      return;
    }

    return (
      <Box sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end"
        }}>
        <Button variant="contained" onClick={(e) => { submit(prompt); }}>
          Let's Go!
        </Button>
      </Box>
    )
  })();

  return (
    <React.Fragment>
      <Typography variant="h6">
        What is the subject of your design?
      </Typography>
      <TextPromptField prompt={prompt} onChange={(e) => { setPrompt(e.target.value); }} />
      {s}
      {h}
    </React.Fragment>
  );
}