import "./firebase";

import './index.css';
import CssBaseline from '@mui/material/CssBaseline';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, Observable } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { AppContext, environment } from "./components/AppContext";
import { HashRouter, Route, Routes } from "react-router-dom";
import { NoMatch } from "./components/NoMatch";
import { QueryClient, QueryClientProvider } from "react-query";
import ProjectLink from "./components/project_link/ProjectLink";
import { ScopedCssBaseline } from "@mui/material";
import { CartProvider } from 'use-shopping-cart'
import { store } from "./redux/store";
import { Provider } from 'react-redux'
import Order_Success from "./components/order/success";
import Layout from "./components/layout/Layout";
import Prompt from "./components/prompt/Prompt";
import ProjectQuery from "./components/admin/ProjectQuery";
import ProjectList from "./components/admin/ProjectList";
import Landing from "./components/landing/Landing";
import CartDialog from "./components/purchase/Cart";

const queryClient = new QueryClient({
  
});

export const uri = (() => {  
  if (environment === 'emulator') {
    // todo [ahmed.kamel] hardcoded project id and region
    return 'http://localhost:5001/ai-tee/us-central1/api/graphql';
  }

  return 'https://us-central1-ai-tee.cloudfunctions.net/api/graphql';
})();

// const auth = getAuth();
const httpLink = createHttpLink({ uri });
// todo [ahmed.kamel] add auth link
// const authLink = setContext(async (_, { headers: h }) => {
//   const user = auth.currentUser;

//   const headers = { ...h };

//   // get the authentication token from local storage if it exists
//   if (user) {
//     // const token = await (async () => {
//     //   if (user.getIdToken) {
//     //     return user.getIdToken();
//     //   }

//     //   // todo [ahmed.kamel] on .build the user will be a plain object
//     //   // ^^ not calling getIdToken will result in stale token
//     //   return (user as unknown as any)["stsTokenManager"]["accessToken"];
//     // })();
//     const token = await user.getIdToken();

//     // const token = await user.getIdToken();
//     if (token) {
//       headers['authorization'] = `Bearer ${token}`;
//     }
//   }
//   return { headers }
// });

// https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
const retryLink = new RetryLink({
  delay: {
    initial: 2000,
    max: 5,
    jitter: true
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      // console.log(error, _operation);
      return !!error;
    }
  }
});

// const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
//   if (graphQLErrors) {
//     graphQLErrors.forEach(({ message, locations, path }) =>
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//       )
//     );

//     // Retry the request, returning the new observable
//     return forward(operation);

//     if (networkError)
//     {
//       console.log(`[Network error]: ${networkError}`);
//     }

//     // return new Observable(observer => {
//     //   (async () => {
//     //     try {
//     //       const subscriber = {
//     //         next: observer.next.bind(observer),
//     //         error: observer.error.bind(observer),
//     //         complete: observer.complete.bind(observer),
//     //       };

//     //       // Retry last failed request
//     //       forward(operation).subscribe(subscriber);
//     //     } catch (error) {
//     //       observer.error(error);
//     //     }
//     //   })();
//     // });
//   }
// });

const client = new ApolloClient({
  // link: authLink.concat(httpLink),
  // link: ApolloLink.from([retryLink, errorLink, httpLink]),
  // link: ApolloLink.from([retryLink, httpLink]),
  link: httpLink,
  cache: new InMemoryCache()
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <AppContext.Provider value={{ environment }}>
        <Provider store={store}>
          <CartProvider
              // mode="payment"
              cartMode="checkout-session"
              // todo [ahmed.kamel] should we move this to .env file?
              stripe={process.env.STRIPE_PUBLIC_KEY as string}
              // stripe="pk_live_uw7uYGwPMDGzjWHdPJaWEXsn00qmfh9eMt"
              shouldPersist={true}
              currency="USD"
            >
              {/* https://www.robinwieruch.de/react-router-nested-routes/ */}
            <ScopedCssBaseline>
              <HashRouter>
                <Routes>
                  <Route path="/" element={<Layout client={client}/>}>
                    <Route index element={<Landing  />} />
                    <Route path="/" element={<Prompt client={client} />} />
                    <Route path="/test" element={<CartDialog client={client} opened={true} />} />
                    <Route path="/order/success" element={<Order_Success />} />
                    <Route path="/ref/:id" element={<ProjectLink client={client} />} />
                    <Route path="/admin/p/:id" element={<ProjectQuery client={client} />} />
                    <Route path="/admin/l" element={<ProjectList client={client} />} />
                    <Route path="/prompt" element={<Prompt client={client} />} />
                    <Route path="*" element={<NoMatch />} />
                  </Route>
                </Routes>
              </HashRouter>
            </ScopedCssBaseline>
          </CartProvider>
        </Provider>
      </AppContext.Provider>
    </React.StrictMode>
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
