import React from "react";
import { Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

export interface ProjectPromptHeaderProps {
  prompt: string;
}

export default function ProjectPromptHeader(props: ProjectPromptHeaderProps) {
  const { prompt } = props;

  return (
    <React.Fragment>
      <Grid container rowSpacing={2}>
        <Grid xs={1}>
          <Typography variant="h3">“</Typography>
        </Grid>
        {/* <Grid xs={10}></Grid>
        <Grid xs={1}></Grid>
        <Grid xs={1}>
        </Grid> */}
        <Grid xs={10} alignContent="center">
          <Typography variant="h5">{prompt}</Typography>
        </Grid>
        {/* <Grid xs={1}></Grid>
        <Grid xs={1}></Grid>
        <Grid xs={10}></Grid> */}
        <Grid xs={1}>
          <Typography variant="h3">”</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}