import { Button, DialogProps, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useNavigate } from 'react-router';
import BrushIcon from '@mui/icons-material/Brush';
import { styled } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel';
import React from 'react';
import MobileLanding from './MobileLanding';
import { Image } from 'mui-image'

export default function Landing() {
  const navigate = useNavigate();

  const goToPrompt = () => {
    navigate("/prompt");
  };


  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  if (!matches) {
    return <MobileLanding />
  }

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid container xs={12}>
        <Grid xs={6}>
          <Typography variant="h1" textAlign={"left"}>
            Dare to be You!
          </Typography>
          <Typography variant="h4" textAlign={"left"}>
            AI-Generated T-Shirt Design & Printing.
          </Typography>
          <Typography variant="h4" textAlign={"left"}>
            Unique designs guaranteed.
          </Typography>
          <Button variant="contained" size="large" startIcon={<BrushIcon />} onClick={(e) => { goToPrompt() }} sx={{ mr: 1, fontWeight: "bolder" }} style={{
              margin: "auto",
              width: "100%"
            }}>
              Design my T-Shirt
          </Button>
        </Grid>
        <Grid xs={6}>
          <Carousel autoPlay={true} animation={"slide"} height={400} sx={{textAlign: "center"}}>
            <img src="/media/front-tee.png" width={"100%"}></img>
            <img src="/media/landing/models/gr.jpeg" width={"100%"}></img>
            <img src="/media/landing/models/y.jpeg" width={"100%"}></img>
          </Carousel>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid xs={4}>
          <Image src={"/media/landing/10.png"} />
        </Grid>
        <Grid container xs={8}>
          <Grid xs={4}>
            <Image src={"/media/landing/1.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/2.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/3.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/4.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/5.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/6.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/7.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/8.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/9.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/10.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/11.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/12.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/13.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/14.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/15.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/16.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/17.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/18.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/19.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/20.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/21.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/22.png"} />
          </Grid>
          <Grid xs={4}>
            <Image src={"/media/landing/23.png"} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}