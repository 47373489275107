import { ApolloClient, gql, NormalizedCacheObject, useQuery } from "@apollo/client";
import { Alert, Box, Divider, LinearProgress, Stack, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps, Typography, useMediaQuery, useTheme } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import * as _ from "lodash";
import React from "react";
import { HEX_COLORS } from "../../constants/colors";
import { SIZE_LABEL } from "../../constants/sizes";
import { CatalogItem, CatalogItemAgeGroup } from "../../generated/graphql";

export interface Sizing {
  variant: Number,
  size: SIZE_LABEL,
  product_image?: string,
  color: string,
  age_group: CatalogItemAgeGroup
}

export interface SizeProps {
  onChange: (data: Sizing) => void;
  client: ApolloClient<NormalizedCacheObject>;
}

// GraphQL
const QUERY_CATALOG = gql`
  query fct {
    catalog {
      id
      size
      color
      age_group
      image
    }
  }
`;

const SIZE_ORDER: Array<SIZE_LABEL> = ['2T', '3T', '4T', '5T', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'];
const SIZE_ORDER_LOOKUP = _.reduce<SIZE_LABEL, Record<SIZE_LABEL, Number>>(SIZE_ORDER, (obj, size, idx) => {
  obj[size] = idx;
  return obj;
}, {} as Record<SIZE_LABEL, Number>);

const DEFAULT_AGE_GROUP = CatalogItemAgeGroup.Men;
const DEFAULT_COLOR_BY_AGE_GROUP = {
  // todo [ahmed.kamel] `unknown` should never happen
  [CatalogItemAgeGroup.Unknown]: "Black",
  [CatalogItemAgeGroup.Women]: "Black",
  [CatalogItemAgeGroup.Men]: "Black",
  [CatalogItemAgeGroup.Kids]: "Black",
  [CatalogItemAgeGroup.Toddlers]: "Black",
};

const DEFAULT_SIZE_BY_AGE_GROUP = {
  // todo [ahmed.kamel] `unknown` should never happen
  [CatalogItemAgeGroup.Unknown]: "L",
  [CatalogItemAgeGroup.Women]: "L",
  [CatalogItemAgeGroup.Men]: "L",
  [CatalogItemAgeGroup.Kids]: "M",
  [CatalogItemAgeGroup.Toddlers]: "3T",
};

const calc = (catalog: CatalogItem[], age_group: CatalogItemAgeGroup, color: string, size: string) => {
  const age_group_items = _.chain(catalog).filter(i => i.age_group === age_group).value();

  const color_items = (() => {
    const ret = _.chain(age_group_items).filter(i => i.color === color).value();
    if (ret.length > 0) {
      return ret;
    }

    return _.chain(age_group_items).filter(i => i.color === DEFAULT_COLOR_BY_AGE_GROUP[age_group]).value();
  })();

  const size_items = (() => {
    const ret = _.chain(color_items).filter(i => i.size === size).value();
    if (ret.length > 0) {
      return ret;
    }

    return _.chain(color_items).filter(i => i.size === DEFAULT_SIZE_BY_AGE_GROUP[age_group]).value();
  })();

  // at this point, size_items should have _1_ item it it.
  return _.first(size_items);
}

const sizesForAgeGroupAndColor = (catalog: CatalogItem[], age_group: string, color: string) => {
  return _.chain(catalog).filter(i => i.color === color && i.age_group === age_group).map(i => i.size).uniq().value();
}

const colorsForAgeGroup = (catalog: CatalogItem[], age_group: string) => {
  return _.chain(catalog).filter(i => {
    return i.age_group === age_group && !_.includes(i.color, "Heather");
  }).map(i => i.color).uniq().value();
}


const notesForSizing = (sizing: Omit<Sizing, 'variant' | 'product_image'>): string[] => {
  if (sizing.age_group === CatalogItemAgeGroup.Women) {
    return ["Women T-Shirts run small, we recomend you size up."];
  }

  return [];
}

export default function Size(props: SizeProps) {
  const { client, onChange } = props;
  
  const [age_group, setAgeGroup] = React.useState<CatalogItemAgeGroup>(DEFAULT_AGE_GROUP);
  const [size, setSize] = React.useState<string>(DEFAULT_SIZE_BY_AGE_GROUP[DEFAULT_AGE_GROUP]);
  const [color, setColor] = React.useState<string>(DEFAULT_COLOR_BY_AGE_GROUP[DEFAULT_AGE_GROUP]);

  const [variant, setVariant] = React.useState<Number>();
  
  const { loading, error, data } = useQuery<{ catalog: CatalogItem[] }>(QUERY_CATALOG, { 
    // client, pollInterval: ms('10s'), variables: { type, id }
    client, variables: { }
  });

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const onAgeGroup: ToggleButtonGroupProps['onChange'] = (e, value) => {
    setAgeGroup(value);
    calcVariant(value, color, size);
  }

  const onColor: ToggleButtonGroupProps['onChange'] = (e, value) => {
    setColor(value);
    calcVariant(age_group as CatalogItemAgeGroup, value, size);
  }

  const onSize: ToggleButtonGroupProps['onChange'] = (e, value) => {
    setSize(value);
    calcVariant(age_group as CatalogItemAgeGroup, color, value);
  }

  const calcVariant = (age_group: CatalogItemAgeGroup, color: string, size: string) => {
    if (data) {
      const item = calc(data.catalog, age_group, color, size);
  
      // todo [ahmed.kamel] this _should_ always be found
      if (item) {
        // todo [ahmed.kamel] do we need to keep color and size and age_group? or just item?
        setColor(item?.color);
        setSize(item?.size);
        setVariant(item.id);

        console.log(item, colors);
        onChange({
          variant: item.id,
          size: size as SIZE_LABEL,
          age_group: item.age_group,
          color: item.color,
          product_image: item.image
          // todo [ahmed.kamel] return color as well (why do we return size?)
        });
      }
    }
  }

  if (!variant) {
    calcVariant(age_group, color, size);
  }

  if (loading) {
    return(
      <Box mt={2}>
        <LinearProgress />
      </Box>
    ) 
  }

  const age_groups = _.chain(data?.catalog).map(i => i.age_group).uniq().value();
  const colors = colorsForAgeGroup(data?.catalog as CatalogItem[], age_group);
  // const sizes = _.chain(data?.catalog).
  const sizes = sizesForAgeGroupAndColor(data?.catalog as CatalogItem[], age_group, color).sort((a, b) => {
    const ai = SIZE_ORDER_LOOKUP[a as SIZE_LABEL];
    const bi = SIZE_ORDER_LOOKUP[b as SIZE_LABEL];
    if (ai > bi) {
      return 1;
    }

    if (ai < bi) {
      return -1;
    }

    return 0;
  });
  
  const btns_age_group = age_groups.map((age_group, index) => (
    <ToggleButton key={`age_group_${age_group}`} value={age_group} style={{ fontWeight: "bolder" }} >
      {age_group}
    </ToggleButton>
  ));

  const btns_color = colors?.map((color, index) => (
    <ToggleButton key={`color_${color}`} value={color} style={{ 
      fontWeight: "bolder", 
      color: HEX_COLORS[color],
      padding: 10
    }}>
    {/* <ToggleButton key={`color_${color}`} value={color} style={{ fontSize: 2 }}> */}
      <CircleIcon />
      {/* &nbsp; */}
    </ToggleButton>
  ));

  const btns_size = sizes?.map((size, index) => (
    <ToggleButton key={`size_${size}`} value={size} style={{ 
      fontWeight: "bolder",
      // padding: "15px 20px"
    }} >
      {size}
    </ToggleButton>
  ));

  const notes = (() => {
    const strs = notesForSizing({ age_group, color, size: size as SIZE_LABEL });
    if (_.size(strs)) {
      return <Alert severity="warning">{strs[0]}</Alert>
    }

    return;
  })();

  return (
    <React.Fragment>
      <Stack spacing={2} alignItems="center">
        <Divider><Typography variant="h6">T-Shirt Style</Typography></Divider>
        <ToggleButtonGroup
          size={desktop? "large" : "small"} 
          color="primary"
          value={age_group}
          exclusive
          onChange={onAgeGroup}
          aria-label="T-Shirt Age Group"
        >
          {btns_age_group}
        </ToggleButtonGroup>
        <Divider><Typography variant="h6">Color</Typography></Divider>
        <ToggleButtonGroup
          size={desktop? "large" : "small"} 
          color="primary"
          value={color}
          exclusive
          onChange={onColor}
          aria-label="T-Shirt Color"
          style={{flexWrap: "wrap"}}
        >
          {btns_color}
        </ToggleButtonGroup>
        <Divider><Typography variant="h6">Size</Typography></Divider>
        <ToggleButtonGroup
          size={desktop? "large" : "small"} 
          color="primary"
          value={size}
          exclusive
          onChange={onSize}
          aria-label="T-Shirt Size"
        >
          {btns_size}
        </ToggleButtonGroup>
        { notes }
      </Stack>
    </React.Fragment>
  );
}