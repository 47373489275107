import { Dialog, DialogContent, DialogProps, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Project, Image } from "../../generated/graphql";
import * as _ from "lodash";

import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import SelectTee from "../purchase/SelectTee";
import { Sizing } from "../purchase/Size";
import { makeProduct } from "../../code/stripe_cart";
import { useShoppingCart } from 'use-shopping-cart'
import CommonDialog from "../common/CommonDialog";

export interface ProjectComponentProps {
  client: ApolloClient<NormalizedCacheObject>;
  image: Image;
  project: Project;
  open: boolean;
  onClose: NonNullable<DialogProps['onClose']>;
}

export default function TeeSelection(props: ProjectComponentProps) {
  const { client, project, image, open, onClose } = props;
  const { addItem } = useShoppingCart()

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md');
  
  const addToCart = async (image: Image, sizing: Sizing) => {
    const product = await makeProduct(project as Project, image, sizing);

    addItem(product);
    // onClose({}, "escapeKeyDown");
  }

  const closeSelectTee = () => {
    onClose({}, "escapeKeyDown");
  };

  const content = (() => { 
    if (image) {
      return <SelectTee client={client} image={image} addToCart={addToCart} close={closeSelectTee}/>
    }
  })();

  return (
    <CommonDialog 
      open={open}
      fullWidth={fullWidth} 
      maxWidth={maxWidth}
      // fullScreen={fullScreen}
      titleNode={undefined} 
      contentNode={content} 
      onClose={onClose} />
  );
}