import { Box, ImageList, ImageListItem, SnackbarContent, useMediaQuery, useTheme } from "@mui/material";
import { Project, Image as TeeImage } from "../generated/graphql";
import * as _ from "lodash";
import { Image } from 'mui-image'
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

export interface ImageGridProps {
  select: (image: TeeImage) => void
  project: Project;
}

export default function ImageGrid(props: ImageGridProps) {
  const { project } = props;

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const images = _.compact(project.images);

  const useSampleUrl = project.createdAt > 1676246400000;

  const gs = desktop? 6 : 12;
  return (
    <Grid container spacing={2}>
      {images.map((item, index) => {
        const url = useSampleUrl? item.sampleurl: item.url;
        return (
          <Grid xs={gs}>
            <Box onClick={() => { props.select(item); }} style={{cursor: "pointer"}}>
              <Image src={url as string} />
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )
}