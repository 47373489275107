import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import StripeCart from '../purchase/CartButton';
import { store } from '../../redux/store';
import { Container } from '@mui/material';

export default function PrimaryAppBar() {
  return (
    // <Box sx={{ flexGrow: 1 }}>
    <React.Fragment>
      <AppBar position="static">
        <Container maxWidth={"md"}>
          <Toolbar>
            <Typography
                variant="h5"
                noWrap
                component="a"
                href="/"
                sx={{
                  color: 'inherit',
                  textDecoration: 'none',
                  flexGrow: 1
                }}
              >
                Generative Gear
            </Typography>
            <StripeCart />
          </Toolbar>
        </Container>
      </AppBar>
      <div style={{visibility: "hidden"}}>
        <AppBar>
          <Container maxWidth={"md"}>
            <Toolbar>
              <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    color: 'inherit',
                    textDecoration: 'none',
                    flexGrow: 1
                  }}
                >
                  Generative Gear
              </Typography>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
    </React.Fragment>
    // </Box>
  );
}