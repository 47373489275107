import * as React from 'react';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { store } from '../../redux/store';
import { useShoppingCart } from 'use-shopping-cart'
import _ from 'lodash';
import { Box, CircularProgress, DialogProps, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { CartEntry } from 'use-shopping-cart/core/index'
import { createCheckoutSession } from '../../code/stripe_cart';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import CommonDialog from '../common/CommonDialog';
import BackspaceIcon from '@mui/icons-material/Backspace';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Image } from 'mui-image'

export interface CartDialogProps {
  client: ApolloClient<NormalizedCacheObject>;
  opened: boolean
}

export default function CartDialog(props: CartDialogProps) {
  const { cartDetails, clearCart, cartCount, removeItem, setItemQuantity } = useShoppingCart();

  const { opened, client } = props;
  const [checkingOut, setCheckingOut] = React.useState(false);
  const theme = useTheme();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md');
  
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const handleClose = () => {
    // setOpen(false);
    store.dispatch(({ type: 'cart/close' }));
  };

  const handleQuantityChange = (e: SelectChangeEvent<number>, item: CartEntry) => {
    setItemQuantity(item.id, Number(e.target.value));
  }

  const handleCheckout = async () => {
    setCheckingOut(true);
    const entries = _.values(cartDetails);
    console.log(entries);
    const ret = await createCheckoutSession(client, entries);
    if (ret.url) {
      // window.open(ret.url,'_newtab');
      window.location.href = ret.url;
    }
    // todo [ahmed.kamel] is this needed?
    store.dispatch(({ type: 'cart/close' }));
  }

  const rm = (item: CartEntry) => {
    removeItem(item.id);
    const entries = _.values(cartDetails);
    if (entries.length === 1) {
      store.dispatch(({ type: 'cart/close' }));
    }
  };

  const cardify = (item: CartEntry, index: string) => {
    return (
      // <Card key={`cart_item_sku_${item.sku}`} sx={{ display: 'flex' }}>
      <React.Fragment>
        <Grid xs={5}>
          <Image src={item.image as string}></Image>
        </Grid>
        <Grid xs={7}>
          {matches && <Typography component="div" variant="h6">{ item.name }</Typography>}
          
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {/* { item.description } */}
            { `${item.description} - $${item.price} each` }
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" component="div">
            {/* { `$${item.price} - quantity ${item.quantity}` } */}
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small">Quantity</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={item.quantity}
                label="Quantity"
                onChange={(e) => handleQuantityChange(e, item)}
              >
                {_.times(25, (n) => {
                  return (<MenuItem value={n}>{n}</MenuItem>)
                })}
              </Select>
            </FormControl>
            {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Button variant="text" onClick={() => rm(item)}>remove</Button>
            </FormControl> */}
          </Typography>
        </Grid>
      </React.Fragment>
    )
  }

  const content = (() => {
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {_.map(cartDetails, (item: CartEntry, index) => {
        return (
          <React.Fragment>
            { cardify(item, index) }
          </React.Fragment>
        )
      })}
      </Grid>
    )
  })();

  const action = (() => {
    return (
      <React.Fragment>
        <Box
          m={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button 
              variant="contained"
              startIcon={<ShoppingCartCheckoutIcon />}
              onClick={handleCheckout}
              autoFocus
              disabled={cartCount==0 || checkingOut}>
              Checkout
            </Button>
            {checkingOut && (
              <CircularProgress
                size={24}
                sx={{
                  // color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
          
        </Box>
      </React.Fragment>
    );
  })();

  return (
    <CommonDialog
      open={opened}
      fullWidth={fullWidth} 
      maxWidth={maxWidth}
      // fullScreen={fullScreen}
      titleNode={undefined} 
      // titleNode={"Cart"} 
      contentNode={content} 
      actionNode={action}
      onClose={handleClose}
    />
  );
}
