import { ApolloClient, gql, NormalizedCacheObject, useMutation } from "@apollo/client";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, FormControl, InputAdornment, InputLabel, LinearProgress, OutlinedInput, TextareaAutosize, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Mutation, Project } from "../../generated/graphql";
import CommonDialog from "../common/CommonDialog";
import { AppContext, environment } from "../AppContext";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const MUTATION_CREATE_REF = gql`
  mutation fct($projectId: ID!, $email: String!) {
    createProjectLink(projectId: $projectId, email: $email)
  }
`;

export interface ProjectSaveDialogProps {
  client: ApolloClient<NormalizedCacheObject>;
  project: Project;
  open: boolean;
  onClose: NonNullable<DialogProps['onClose']>;
};

export default function ProjectSaveDialog(props: ProjectSaveDialogProps) {
  const { client, project, open, onClose } = props;
  const [email, setEmail] = React.useState<string>(localStorage.getItem("email") || "");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md');

  const [make_ref, res_ref] = useMutation<Pick<Mutation, 'createProjectLink'>>(MUTATION_CREATE_REF, { 
    client, variables: { }
  });

  const save = () => {
    localStorage.setItem("email", email);

    make_ref({ variables: { projectId: project.id, email } }).then((x) => {
      // console.log(x);
    });
  };

  const handleClose = (e: {}) => {
    onClose(e, "escapeKeyDown");
  };

  const { title, content } = (() => {
    if (res_ref.called) {
      if (res_ref.loading) {
        return {
          title: undefined,
          content: <LinearProgress />
        }
      }
  
      return {
        title: "Link emailed to",
        content: (<React.Fragment>
          <DialogContentText>
            <TextField fullWidth defaultValue={email} InputProps={{ readOnly: true }} variant="standard"/>
          </DialogContentText>
          {
            environment === 'emulator'
              ? (<DialogContentText>
                temp: <a href={res_ref.data?.createProjectLink}>
                  {res_ref.data?.createProjectLink}
                </a>
              </DialogContentText>)
              : undefined
          }
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button variant="contained" onClick={handleClose} sx={{ mr: 1 }}>
              Ok
            </Button>
          </Box>
        </React.Fragment>)
      }
    }

    return {
      title: "Save your design",
      content: (<React.Fragment>
        <DialogContentText>
          Enter your email address below and we will send you a link to your design.

          Use the link to come back to your design at any time.
        </DialogContentText>
        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-adornment-amount">Email</InputLabel>
          <OutlinedInput
            // id="outlined-adornment-amount"
            // startAdornment={<InputAdornment position="start">$</InputAdornment>}
            value={email}
            label="Email"
            onChange={(e) => { setEmail(e.target.value); }}
          />
        </FormControl>
        <Box
          m={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button variant="contained" onClick={(e) => { save(); }} sx={{ mr: 1 }}>
            Save
          </Button>
        </Box>
     </React.Fragment>)
    }
  })();
  

  return (
    <CommonDialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      // fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      titleNode={title}
      contentNode={content}
      />
  )
}