import { Box, Button, ButtonGroup, Container, Dialog, DialogContent, DialogContentText, DialogProps, DialogTitle, Modal, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Project, Image } from "../../generated/graphql";
import * as _ from "lodash";
import ImageGrid from "../ImageGrid";
// import EmailRef from "./EmailRef";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import TeeSelection from "./TeeSelection";
import ProjectSaveDialog from "./ProjectSaveDialog";
import ProjectPromptHeader from "./ProjectPromptHeader";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

export interface ProjectComponentProps {
  client: ApolloClient<NormalizedCacheObject>;
  meh?: () => void
  project: Project;
}

export default function ProjectComponent(props: ProjectComponentProps) {
  const { client, project, meh } = props;

  const theme = useTheme();
 
  const [openTeeSelection, setOpenTeeSelection] = React.useState(false);
  const [openProjectSaveDialog, setOpenProjectSaveDialog] = React.useState(false);
  const [image, setImage] = React.useState<Image>();
  
  const select = (image: Image) => {
    setImage(image);
    setOpenTeeSelection(true);
  }

  const save = () => {
    setOpenProjectSaveDialog(true);
  }

  const handleCloseTeeSelection = () => {
    setOpenTeeSelection(false);
  };

  const handleCloseProjectSaveDialog = () => {
    setOpenProjectSaveDialog(false);
  };

  return (
    <React.Fragment>
      <Grid container rowSpacing={2}>
        <Grid xs={12}>
          <ProjectPromptHeader prompt={project.prompt} />
        </Grid>
        {
          meh && (
          <Grid xs={12}>
            <Stack direction="row" spacing={3}>
              <Button variant="outlined" color="error" startIcon={<ThumbDownOffAltIcon />} onClick={meh}>Meh, Let's try again</Button>
              <Button variant="contained" color="success" startIcon={<SaveAltIcon />} onClick={save}>Save</Button>
            </Stack>
          </Grid>
          )
        }
        <Grid xs={12}>
          <ImageGrid project={project} select={select} />
        </Grid>
      </Grid>
      {
        image && <TeeSelection client={client} image={image} project={project} open={openTeeSelection} onClose={handleCloseTeeSelection}/>
      }
      <ProjectSaveDialog client={client} project={project} open={openProjectSaveDialog} onClose={handleCloseProjectSaveDialog}/>
    </React.Fragment>
  );
}