import { Box, Button, Dialog, DialogContent, DialogContentText, DialogProps, DialogTitle, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { useNavigate } from 'react-router';
import { useShoppingCart } from 'use-shopping-cart'
import CommonDialog from '../common/CommonDialog';

export default function Order_Success() {
  const navigate = useNavigate();
  const { clearCart } = useShoppingCart();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md');

  const handleClose = () => {
    // todo [ahmed.kamel] move to on load not on close
    clearCart();
    navigate('/');
  };

  const content = (
    <DialogContentText>
      <Typography variant='h4'>
        Thank you for your order. Check your email for more details.
      </Typography>
    </DialogContentText>
  );

  return (
    <CommonDialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      // fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      titleNode={undefined}
      contentNode={content}
    />
  )
}