import { ApolloClient, gql, NormalizedCacheObject, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import { Image as TeeImage, CatalogItem, CatalogItemAgeGroup } from "../../generated/graphql";
import { Sizing } from "./Size";
import SizeAndPreview from "./SizeAndPreview";
import Carousel from 'react-material-ui-carousel'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Image } from 'mui-image'
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import CheckIcon from '@mui/icons-material/Check';

export interface SelectTeeProps {
  addToCart: (image: TeeImage, sizing: Sizing) => void;
  image: TeeImage;
  client: ApolloClient<NormalizedCacheObject>;
  close: () => void;
}

const bg_position = (group: CatalogItemAgeGroup): {w: string, x: string, y: string} => {
  switch(group) {
    case CatalogItemAgeGroup.Women:
      return { w: "170%", x: "55%", y: "center" };
    case CatalogItemAgeGroup.Kids:
      return { w: "160%", x: "53%", y: "center" };
    default:
      return { w: "150%", x: "center", y: "center" };
  }
}

export default function SelectTee(props: SelectTeeProps) {
  const { image, client, close } = props;
  const theme = useTheme();

  const [sizing, setSizing] = React.useState<Sizing>();
  const [addedToCart, setAddedToCart] = React.useState<boolean>(false);
  const [added, setAdded] = React.useState<number>(0);

  const onChange = (data: Sizing) => {
    setSizing(data);
  };
  
  const addToCart = () => {
    props.addToCart(image, sizing as Sizing);
    setAddedToCart(true);
    setAdded(added + 1);
  }

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const carousel = (() => {
    if (!sizing) {
      return (<Image src={image.url as string} />)
    }
    
    const { w, x, y } = bg_position(sizing.age_group);
    // https://developer.mozilla.org/en-US/docs/Web/CSS/background-position
    if (desktop) {
      return (
        <Carousel autoPlay={false} animation={"slide"} height={440} sx={{textAlign: "center"}}>
          <div style={{
              backgroundImage: `url(${sizing.product_image})`,
              width: 400,
              height: 450,
              backgroundRepeat: "no-repeat",
              backgroundPosition: `${x} ${y}`,
              backgroundSize: w,
            }}>
            <Image src={image.url as string} style={{ width: 190, height: 190 }} />
          </div>
          <Image src={image.url as string} />
        </Carousel>
      )
    }

    return (
      <Carousel autoPlay={false} animation={"slide"} height={380} sx={{textAlign: "center"}}>
        <div style={{
            backgroundImage: `url(${sizing.product_image})`,
            width: "100%",
            height: 350,
            backgroundRepeat: "no-repeat",
            backgroundPosition: `${x} ${y}`,
            backgroundSize: w,
          }}>
          <Image src={image.url as string} style={{ width: 130, height: 130 }} />
        </div>
        <Image src={image.url as string} />
      </Carousel>
    )
  })();

  const sizeAndPreview = (<SizeAndPreview client={client} image={image} onChange={onChange} />);

  const cb = (
    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
      <Button variant="contained" size="large" color="error" onClick={(e) => { close(); }}>
        Close
      </Button>
    </Box>
  )

  const ab = (() => {
    if (addedToCart) {
      return (
        <Button variant="contained" disabled={!sizing} size="large" startIcon={<CheckIcon /> } onClick={(e) => { addToCart() }} sx={{ mr: 1, fontWeight: "bolder" }} style={{
          margin: "auto",
          width: "100%"
        }}>
          Added {added} to Cart
        </Button>
      )
    }

    return (
      <Button variant="contained" disabled={!sizing} size="large" startIcon={<AddShoppingCartIcon />} onClick={(e) => { addToCart() }} sx={{ mr: 1, fontWeight: "bolder" }} style={{
        margin: "auto",
        width: "100%"
      }}>
        Add to Cart - $45
      </Button>
    )
  })();
    
    // <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
    // </Box>
  // )

  if (desktop) {
    return (
      <Grid container spacing={2}>
        <Grid xs={6}>
          { carousel }
        </Grid>
        {/* <Grid xs={1}></Grid> */}
        <Grid xs={6}>
          { sizeAndPreview }
        </Grid>
        <Grid xs={5}>
          {/* { cb } */}
        </Grid>
        <Grid xs={1}></Grid>
        <Grid xs={6}>
          { ab }
        </Grid>
      </Grid>
    );
  }
  
  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        { carousel }
      </Grid>
      <Grid xs={12}>
        { sizeAndPreview }
      </Grid>
      <Grid xs={12}>
        { ab }
      </Grid>
      {/* <Grid xs={12}>
        { cb }
      </Grid> */}
    </Grid>
  )
}