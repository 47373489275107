import { Button, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useNavigate } from 'react-router';
import BrushIcon from '@mui/icons-material/Brush';
import { styled } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel';
import { Image } from 'mui-image'

export default function MobileLanding() {
  const navigate = useNavigate();

  const goToPrompt = () => {
    navigate("/prompt");
  };

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid xs={12}>
        <Typography variant="h3" textAlign={"center"}>
          Dare to be You!
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Typography variant="h5" textAlign={"center"}>
          AI-Generated T-Shirt Design & Printing.
        </Typography>
        <Typography variant="h5" textAlign={"center"}>
          Unique designs guaranteed.
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Carousel autoPlay={true} animation={"slide"} height={300} sx={{textAlign: "center"}}>
          <img src="/media/front-tee.png" height={"100%"}></img>
          <img src="/media/landing/models/gr.jpeg" height={"100%"}></img>
          <img src="/media/landing/models/y.jpeg" height={"100%"}></img>
        </Carousel>
      </Grid>
      <Grid xs={12}>
        <Button variant="contained" size="large" startIcon={<BrushIcon />} onClick={(e) => { goToPrompt() }} sx={{ mr: 1, fontWeight: "bolder" }} style={{
            margin: "auto",
            width: "100%"
          }}>
            Design my T-Shirt
        </Button>
      </Grid>
      <Grid container xs={12}>
        <Grid xs={6}>
          <Image src={"/media/landing/10.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/1.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/2.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/3.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/4.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/5.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/6.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/7.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/8.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/9.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/10.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/11.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/12.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/13.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/14.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/15.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/16.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/17.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/18.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/19.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/20.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/21.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/22.png"} />
        </Grid>
        <Grid xs={6}>
          <Image src={"/media/landing/23.png"} />
        </Grid>
      </Grid>
    </Grid>
  )
}