import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, LinearProgress, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ProjectPromptHeader from "../project/ProjectPromptHeader";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Image } from 'mui-image'
import { linearProgressClasses } from '@mui/material/LinearProgress';

export interface PromptProps {
  prompt: string;
}

// const SlowLinearProgress = styled(LinearProgress)({
//   "& .MuiLinearProgress-bar": {
//     // apply a new animation-duration to the `.bar` class
//     animationDuration: "8s"
//   }
// });

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

export default function Prompt(props: PromptProps) {
  const { prompt } = props;

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((old) => {
        return old + 5;
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      // fullScreen={fullScreen}
      open={true}
      // onClose={handleClose}
      // aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <DialogContentText>
        <Grid container rowSpacing={2}>
          <Grid xs={12}>
            <ProjectPromptHeader prompt={prompt} />
          </Grid>
          <Grid xs={12}>
            Generating images may take a few minutes.
          </Grid>
          <Grid xs={12}>
            { (progress === 100)
                ? <BorderLinearProgress />
                : <BorderLinearProgress variant="determinate" value={progress} />
            }
          </Grid>
          <Grid xs={12} alignContent={"center"}>
            <Image src="/media/wait.jpeg" />
          </Grid>
        </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}