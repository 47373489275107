/* eslint-disable */
import { GraphQLResolveInfo } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type CartItem = {
  age_group: Scalars['String'];
  color: Scalars['String'];
  imageId: Scalars['String'];
  projectId: Scalars['ID'];
  quantity: Scalars['Int'];
  size: Scalars['String'];
};

export type CatalogItem = {
  __typename?: 'CatalogItem';
  age_group: CatalogItemAgeGroup;
  color: Scalars['String'];
  id: Scalars['Int'];
  image: Scalars['String'];
  in_stock?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['String']>;
  size: Scalars['String'];
};

export enum CatalogItemAgeGroup {
  Kids = 'KIDS',
  Men = 'MEN',
  Toddlers = 'TODDLERS',
  Unknown = 'UNKNOWN',
  Women = 'WOMEN'
}

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  id: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  filename: Scalars['String'];
  sampleurl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  createCheckoutSession: CheckoutSession;
  createProject: Project;
  createProjectLink: Scalars['String'];
  requestTeePreview: Scalars['String'];
};


export type MutationCreateCheckoutSessionArgs = {
  items?: InputMaybe<Array<InputMaybe<CartItem>>>;
};


export type MutationCreateProjectArgs = {
  oid: Scalars['String'];
  prompt: Scalars['String'];
};


export type MutationCreateProjectLinkArgs = {
  email: Scalars['String'];
  projectId: Scalars['ID'];
};


export type MutationRequestTeePreviewArgs = {
  imageUrl: Scalars['String'];
  variant_id: Scalars['Int'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  filename: Scalars['String'];
  id: Scalars['ID'];
  projectId: Scalars['ID'];
  variant_id: Scalars['Int'];
};

export type Owner = {
  __typename?: 'Owner';
  oid: Scalars['String'];
  oid_type: Scalars['String'];
};

export type Project = {
  __typename?: 'Project';
  createdAt: Scalars['Float'];
  id: Scalars['ID'];
  images: Array<Maybe<Image>>;
  owner?: Maybe<Owner>;
  prompt: Scalars['String'];
};

export type ProjectLink = {
  __typename?: 'ProjectLink';
  email: Scalars['String'];
  id: Scalars['ID'];
  project: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  catalog: Array<Maybe<CatalogItem>>;
  getTeePreviewResult: Scalars['String'];
  project?: Maybe<Project>;
  projectLink?: Maybe<Project>;
  projects: Array<Project>;
};


export type QueryGetTeePreviewResultArgs = {
  jobId: Scalars['String'];
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
};


export type QueryProjectLinkArgs = {
  id: Scalars['ID'];
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  CartItem: CartItem;
  CatalogItem: ResolverTypeWrapper<CatalogItem>;
  CatalogItemAgeGroup: CatalogItemAgeGroup;
  CheckoutSession: ResolverTypeWrapper<CheckoutSession>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Image: ResolverTypeWrapper<Image>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Mutation: ResolverTypeWrapper<{}>;
  OrderItem: ResolverTypeWrapper<OrderItem>;
  Owner: ResolverTypeWrapper<Owner>;
  Project: ResolverTypeWrapper<Project>;
  ProjectLink: ResolverTypeWrapper<ProjectLink>;
  Query: ResolverTypeWrapper<{}>;
  String: ResolverTypeWrapper<Scalars['String']>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Boolean: Scalars['Boolean'];
  CartItem: CartItem;
  CatalogItem: CatalogItem;
  CheckoutSession: CheckoutSession;
  Float: Scalars['Float'];
  ID: Scalars['ID'];
  Image: Image;
  Int: Scalars['Int'];
  Mutation: {};
  OrderItem: OrderItem;
  Owner: Owner;
  Project: Project;
  ProjectLink: ProjectLink;
  Query: {};
  String: Scalars['String'];
};

export type CatalogItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CatalogItem'] = ResolversParentTypes['CatalogItem']> = {
  age_group?: Resolver<ResolversTypes['CatalogItemAgeGroup'], ParentType, ContextType>;
  color?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  image?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  in_stock?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CheckoutSessionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckoutSession'] = ResolversParentTypes['CheckoutSession']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']> = {
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sampleurl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  _empty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createCheckoutSession?: Resolver<ResolversTypes['CheckoutSession'], ParentType, ContextType, Partial<MutationCreateCheckoutSessionArgs>>;
  createProject?: Resolver<ResolversTypes['Project'], ParentType, ContextType, RequireFields<MutationCreateProjectArgs, 'oid' | 'prompt'>>;
  createProjectLink?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationCreateProjectLinkArgs, 'email' | 'projectId'>>;
  requestTeePreview?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationRequestTeePreviewArgs, 'imageUrl' | 'variant_id'>>;
};

export type OrderItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderItem'] = ResolversParentTypes['OrderItem']> = {
  filename?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  projectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  variant_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OwnerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Owner'] = ResolversParentTypes['Owner']> = {
  oid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  oid_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['Project'] = ResolversParentTypes['Project']> = {
  createdAt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  images?: Resolver<Array<Maybe<ResolversTypes['Image']>>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Owner']>, ParentType, ContextType>;
  prompt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProjectLink'] = ResolversParentTypes['ProjectLink']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  project?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  _empty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  catalog?: Resolver<Array<Maybe<ResolversTypes['CatalogItem']>>, ParentType, ContextType>;
  getTeePreviewResult?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGetTeePreviewResultArgs, 'jobId'>>;
  project?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType, RequireFields<QueryProjectArgs, 'id'>>;
  projectLink?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType, RequireFields<QueryProjectLinkArgs, 'id'>>;
  projects?: Resolver<Array<ResolversTypes['Project']>, ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  CatalogItem?: CatalogItemResolvers<ContextType>;
  CheckoutSession?: CheckoutSessionResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  OrderItem?: OrderItemResolvers<ContextType>;
  Owner?: OwnerResolvers<ContextType>;
  Project?: ProjectResolvers<ContextType>;
  ProjectLink?: ProjectLinkResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
};

