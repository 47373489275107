import { app } from "../firebase";
import React from "react";
import { connectAuthEmulator, getAuth } from "firebase/auth";

export interface AppContextType {
  environment: 'production' | 'demo' | 'emulator' | '[unknown]';
  // user?: User;
}

export const AppContext = React.createContext<AppContextType>({
  environment: '[unknown]'
});

export const environment: AppContextType['environment'] = (() => {
  if (window.location.hostname.startsWith('localhost')) {
    return 'emulator';
  }

  if (window.location.hostname.startsWith('127.0.0.1')) {
    return 'emulator';
  }

  return 'production';
})();

const auth = getAuth(app);
if (environment === 'emulator') {
  connectAuthEmulator(auth, "http://localhost:9099");
}