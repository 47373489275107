import { FormControl, StandardTextFieldProps, TextField } from "@mui/material";
import React from "react";
import _ from "lodash";

export interface TextPromptProps {
  prompt: string | undefined;
  onChange: NonNullable<StandardTextFieldProps['onChange']>
}

const MAX_LEN = 400;

export default function TextPromptField(props: TextPromptProps) {
  const { prompt, onChange } = props;

  const [len, setLen] = React.useState<number>(0);

  return (
    <React.Fragment>
      <FormControl fullWidth sx={{ m: 1 }}>
        <TextField
          type="text"
          value={prompt}
          label="Prompt"
          onChange={(e) => {
            setLen(e.target.value.length);
            onChange(e);
          }}
          multiline
          maxRows={4}
          // https://aguidehub.com/blog/mui-textfield-set-max-length/
          inputProps={{ maxLength: MAX_LEN }}
          helperText={`${len} / ${MAX_LEN}`}
        />

      </FormControl>
    </React.Fragment>
  );
}