import { Check } from "@mui/icons-material";
import { Stack, Step, StepIconProps, StepLabel, Stepper, styled, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { prompt_keywords } from "../../constants/prompt_keywords";
import KeywordByImage from "./KeywordByImage";
import TextPrompt from "./TextPrompt";
import TextPromptField from "./TextPromptField";

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const hints = [
  "Clownish beaver eating poutine",
  "Pink rhinoceros skating",
  "Cute boxer gesticulating",
  "Rainbow doctor winking",
  "Teal fried chicken squawking",
  "Summer Hermes Birkin bag teetering"
];

const keyprompts = [
  prompt_keywords.illustrationtype,
  prompt_keywords.angle,
  // prompt_keywords.closeness,
  prompt_keywords.colorpalette,
  prompt_keywords.vibe,
];

const steps = ['Prompt', ...keyprompts.map(i => i.title), 'Confirm' ];

export interface PromptProps {
  prompt: string | undefined;
  onComplete: (prompt: string) => void;
}

export default function PromptBuilder(props: PromptProps) {
  const { onComplete } = props;

  const [prompt, setPrompt] = React.useState<string>(props.prompt ?? "");
  // const [built, setBuilt] = React.useState<string>("");
  const [keywords, setKeywords] = React.useState<Array<string | undefined>>([]);
  const [step, setStep] = React.useState(0);

  const onPromptSubmit = (str: string) => {
    setPrompt(str);
    // setBuilt(str);
    setStep(step+1);
  };

  const onPromptConfirm = (str: string) => {
    setPrompt(str);
    // setBuilt(str);
    setStep(step+1);

    onComplete(str)
  };

  const onKeywordSelect = (str: string | undefined) => {
    const conf_idx = step - 1;
    const arr = [...keywords];
    arr[conf_idx] = str;
    setKeywords(arr);

    // const built = (() => {
    //   const keystr = _.compact(arr.map((i, idx) => {
    //     if (i) {
    //       return keyprompts[idx].stringify(i);
    //     }

    //     return undefined;
    //   }));

    //   if (keystr.length) {
    //     return `${prompt}; ${keystr.join('; ')}`;
    //   }

    //   return prompt;
    // })();

    // setBuilt(built);
    if (str) {
      setPrompt(`${prompt}; ${keyprompts[conf_idx].stringify(str)}`);
    }

    const next = step+1;

    setStep(next);
  }

  const content = (() => {
    switch(step) {
      case 0:
        return <TextPrompt prompt={prompt} hints={hints} submit={onPromptSubmit} />
      case 1:
      case 2:
      case 3:
      case 4:
      // case 5:
        const conf = keyprompts[step -1];
        return (
          <React.Fragment>
            <TextPromptField prompt={prompt} onChange={(e) => { setPrompt(e.target.value); }} />
            <KeywordByImage 
              description={conf.description} 
              title={conf.title} 
              select={onKeywordSelect} 
              keywords={conf.keywords}/>
          </React.Fragment>
        );
      case 5:
        // return <TextPrompt prompt={built} submit={onPromptConfirm} />
        return <TextPrompt prompt={prompt} submit={onPromptConfirm} />
      default:
        return ("all done");
    }
  })();
  
  return (
    <React.Fragment>
      <Stack sx={{ width: '100%' }} spacing={4}>
        <Typography variant="h3" align="center">
          Generate a unique image
        </Typography>
        <Stepper alternativeLabel activeStep={step} /*connector={<QontoConnector />}*/>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {content}
      </Stack>
    </React.Fragment>
  )
}