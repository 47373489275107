import { Box, DialogActions, DialogTitle, IconButton } from "@mui/material";
import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { rm } from "fs";
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

export interface CommonDialogProps extends DialogProps {
  titleNode: React.ReactNode,
  contentNode: React.ReactNode,
  actionNode?: React.ReactNode,
  onClose: NonNullable<DialogProps['onClose']>;
}

export default function CommonDialog(props: CommonDialogProps) {
  const { fullWidth, maxWidth, fullScreen, open, onClose } = props;
  const { titleNode, contentNode, actionNode } = props;

  const close = (e: {}) => {
    onClose(e, "escapeKeyDown");
  };

  return (
    <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <Grid container xs={12}>
            <Grid xs={11} />
            <Grid xs={1} textAlign="right">
              <IconButton aria-label="close">
                <CloseIcon onClick={(e) => close(e)}/>
              </IconButton>
            </Grid>
          </Grid>
          {titleNode}
        </DialogTitle>
        <DialogContent style={{marginTop: 10}}>
          {contentNode}
        </DialogContent>
        {
          actionNode && <DialogActions>{ actionNode }</DialogActions>
        }
     </Dialog>
  )
}
