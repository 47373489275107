import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { LinearProgress, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useCreateProject } from "../../code/project_create";
import { Project } from "../../generated/graphql";
import PromptBuilder from "./PromptBuilder";
import { useNavigate } from 'react-router';
import ProjectComponent from "../project/ProjectComponent";
import PromptGenProgressDialog from "./PromptGenProgressDialog";

export interface PromptProps {
  client: ApolloClient<NormalizedCacheObject>;
}

enum Page {
  Prompt,
  Generate,
  Select
}

export default function Prompt(props: PromptProps) {
  const { client } = props;

  const [prompt, setPrompt] = React.useState<string>("");
  const [page, setPage] = React.useState<Page>(Page.Prompt);
  const [project, setProject] = React.useState<Project>();

  const navigate = useNavigate();
  const { mutate, response } = useCreateProject(client);

  const meh = () => {
    setPage(Page.Prompt);
  };

  const onPrompt = (prompt: string) => {
    setPrompt(prompt);
    setPage(Page.Generate);

    mutate({
      variables: { account: "[unknown]", prompt },
      update: (proxy, { data }) => {
        const project = data?.createProject;
        if (project) {
          // navigate(`/project/:${project.id}`);
          setProject(project);
          // setTimeout(() => {
          //   setPage(Page.Select);
          // }, 60 * 1000);
          setPage(Page.Select);
          // onComplete(project);
        }

        // todo [ahmed.kamel] handle error
      }
    })
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const content = (() => {
    if (page === Page.Prompt) {
      return <PromptBuilder onComplete={onPrompt} prompt={prompt}></PromptBuilder>
    }

    if (page === Page.Generate) {
      return (
        <PromptGenProgressDialog prompt={prompt} />
      ) 
    }

    // Select
    return <ProjectComponent client={client} meh={meh} project={project as Project} />
  })();

  return (
    content
  );
}