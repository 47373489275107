export const HEX_COLORS: Record<string, string> = {
  'White': '#ffffff',
  'Black': '#000000',
  'Aqua': '#158aad',
  'Ash': '#dfe0d8',
  'Asphalt': '#565654',
  'Berry': '#b43373',
  'Brown': '#3c322d',
  'Burnt Orange': '#d97d4b',
  'Gold': '#eb9911',
  'Kelly': '#2b8f65',
  'Leaf': '#5f8d4d',
  'Light Blue': '#c0c7d1',
  'Maroon': '#6f283e',
  'Navy': '#1b1927',
  'Ocean Blue': '#619cb8',
  'Olive': '#5d6438',
  'Pink': '#e9b4bc',
  'Red': '#ab1224',
  'Silver': '#d3d4cf',
  'Soft Cream': '#d5c4b4',
  'Steel Blue': '#69889f',
  'True Royal': '#134887',
  'Yellow': '#edca6c',
  'Athletic Heather': '#c3c3c1',
  'Army': '#534d3e',
  'Forest': '#2e4631',
  'Dark Grey Heather': '#333333',
  'Heather Blue': '#adbbdb',
  'Heather Deep Teal': '#42697a',
  'Heather Forest': '#343f37',
  'Heather Midnight Navy': '#373550',
  'Heather Mint': '#a3d9bf',
  'Heather Orange': '#ee7659',
  'Heather Raspberry': '#bf3f60',
  'Heather True Royal': '#52679f',
  'Black Heather': '#191919',
  'Heather Prism Ice Blue': '#c3ccce',
  'Heather Prism Peach': '#ddb7ac',
  'Heather Prism Lilac': '#d0aac1',
  'Heather Prism Mint': '#a1c6ad',
  'Heather Prism Dusty Blue': '#a0bbb2',
  'Mauve': '#b36f6f',
  'Oxblood Black': '#261617',
  'Heather Orchid': '#b18085',
  'Heather Dust': '#d8d1c7',
  'Lilac': '#e0aec3',
  'Mustard': '#db9a32',
  'Autumn': '#b65721',
  'Citron': '#e4e9d3',
  'Strobe': '#d2c168',
  'Tan': '#c9ab8b',
  'Toast': '#af6738',
  // WOMENS
  'Poppy': '#ff3323',
};