import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { CartItem, CheckoutSession, Project, Image } from "../generated/graphql";
import { CartEntry } from "use-shopping-cart/core"
import _ from "lodash";
import { Sizing } from "../components/purchase/Size";
import { Product } from "../redux/cart_item";

export const makeProduct = async (
  project: Project,
  image: Image, 
  sizing: Sizing
  ) => {
    const sku = `sku_${project.id}_${image.filename}_${sizing.age_group}_${sizing.color}_${sizing.size}`
    const metadata: CartItem = {
      projectId: project.id,
      imageId: image.filename,
      size: sizing.size,
      color: sizing.color,
      age_group: sizing.age_group,
      quantity: 1
    }

    const product: Product = {
      name: project.prompt,
      description: `${sizing.age_group} - ${sizing.color} - ${sizing.size}`,
      // todo [ahmed.kamel] do we need this id?
      id: sku,
      sku,
      price: 45,
      image: (image.sampleurl ?? image.url) as string,
      // attribution: 'Photo by Priscilla Du Preez on Unsplash',
      currency: 'USD',
      metadata
    };
  
    return product;
}

const MUTATION_CREATE_CHECKOUT_SESSION = gql`
mutation fct($items: [CartItem]!) {
  createCheckoutSession(items: $items) {
    id
    url
  }
}
`;

export const createCheckoutSession = async (
  client: ApolloClient<NormalizedCacheObject>, 
  entries: CartEntry[]
  ): Promise<CheckoutSession> => {

  const items = _.map<CartEntry, CartItem>(entries, entry => {
    return {
      ...entry.metadata as CartItem,
      quantity: entry.quantity
    };
  });

  const result = await client.mutate<{ createCheckoutSession: CheckoutSession }>({
    mutation: MUTATION_CREATE_CHECKOUT_SESSION,
    variables: {
      items
    }
  });

  if (result.data) {
    return result.data.createCheckoutSession;
  }

  throw result.errors;
};